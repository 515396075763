import React, { lazy, Suspense} from 'react'
import { useInView } from 'react-intersection-observer'

const browser = typeof window !== "undefined" && window


const NotFound = lazy(() => import('../components/notFound'))

const NotFoundPage = () => {
  const [ref, inView] = useInView({
    triggerOnce: true
  })
  return (
    browser && (
      <div className='site__main f jcc aic site__error mxa ac'>
        <React.Fragment>
          <Suspense fallback={<div className='f jcc aic'><h1 className='offlimits caps'>Not Found...</h1></div>}>
            <NotFound />
          </Suspense>
        </React.Fragment>
      </div>
    )
  )
}

export default NotFoundPage
